import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, set, remove } from "firebase/database";
import GoogleButton from "react-google-button";
import "./App.css";

const firebaseConfig = {
	apiKey: "AIzaSyAGJ7lG8uh3VD6Wd8xE7lW0W8DXPVyQLVo",
	authDomain: "flynn-notes-app.firebaseapp.com",
	projectId: "flynn-notes-app",
	storageBucket: "flynn-notes-app.appspot.com",
	messagingSenderId: "221227170254",
	appId: "1:221227170254:web:ad56418c219c1c4762539f",
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);
const db = getDatabase();

// Stole from Stack Overflow :D
function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}
function useWindowScroll() {
	const [scroll, setScroll] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateScroll() {
			setScroll([
				document.getElementsByTagName("html")[0].scrollLeft,
				document.getElementsByTagName("html")[0].scrollTop,
			]);
		}
		window.addEventListener("scroll", updateScroll);
		updateScroll();
		return () => window.removeEventListener("scroll", updateScroll);
	}, []);
	return scroll;
}

function signIn() {
	signInWithPopup(auth, provider)
		.then((result) => {
			// const credential = GoogleAuthProvider.credentialFromResult(result);
			// const token = credential.accessToken;
			// const user = result.user;
			console.log("Signed in!");
		})
		.catch((error) => {
			// const errorCode = error.code;
			// const errorMessage = error.message;
			// const email = error.customData.email;
			// const credential = GoogleAuthProvider.credentialFromError(error);
			console.log("An error occurred while signing in! :(");
		});
}

function App() {
	const [signedIn, setSignedIn] = useState(null);
	const [sortedNoteIDs, setSortedNoteIDs] = useState([]);
	const [windowWidth, windowHeight] = useWindowSize();
	const [, scrollY] = useWindowScroll();
	const [notes, setNotes] = useState({});
	const [notesRetrieved, setNotesRetrieved] = useState(false);
	const defaultGridNoteWidth = 250;
	const columns = Math.max(Math.floor((windowWidth - 40) / (defaultGridNoteWidth + 10)), 1);
	const gridNoteWidth = columns > 1 ? defaultGridNoteWidth : windowWidth - 50;
	const gridNoteHeight = 150;
	const [noteToShow, setNoteToShow] = useState(-1);
	const [showNote, setShowNote] = useState(false);

	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
			console.log("Signed in:", !!user);
			setSignedIn(!!user);
			if (user) {
				const userDataRef = ref(db, "users/" + user.uid);
				onValue(userDataRef, (snapshot) => {
					const data = snapshot.val();
					console.log("User data:", data);
					if (data) setNotes(data);
					else setNotes({});
					setNotesRetrieved(true);
				});
			}
		});
	}, []);

	useEffect(() => {
		const sortedIDs = Object.keys(notes).sort((a, b) => (notes[b].edited ?? 0) - (notes[a].edited ?? 0));
		setSortedNoteIDs(sortedIDs);
	}, [notes]);

	return (
		<>
			<div className="appHeader" style={{ "--dim-color": showNote ? "#0008" : "" }}>
				<h1>Flynn's Notes App</h1>
				{signedIn && (
					<div
						className={"createNote " + (showNote && noteToShow === -2 ? "createNoteHide" : "")}
						style={{
							width:
								windowWidth <= 352 && (!showNote || noteToShow > -1)
									? windowWidth - 52
									: windowWidth <= 550 && showNote && noteToShow === -2
									? windowWidth - 22
									: "",
							height: showNote && noteToShow === -2 ? windowHeight * 0.8 - 22 : "",
							transform:
								showNote && noteToShow === -2
									? `translateX(${windowWidth > 550 ? -116 : -windowWidth / 2 + 159}px) translateY(${
											(windowHeight - 25) / 2 - (windowHeight * 0.8) / 2 + 7.5 + scrollY - 1
									  }px)`
									: windowWidth <= 352
									? `translateX(${-windowWidth / 2 + 176}px)`
									: "",
						}}
						onClick={() => {
							if (!showNote) {
								setNoteToShow(-2);
								setShowNote(true);
							}
						}}>
						<p>Take a note...</p>
					</div>
				)}
			</div>
			<div
				className="notesApp"
				style={{ height: Math.ceil(Object.keys(notes).length / columns) * (gridNoteHeight + 10) + 40 }}>
				<div
					className="notes"
					style={{ width: columns > 1 ? columns * (gridNoteWidth + 10) : windowWidth - 40 }}>
					{sortedNoteIDs.map(
						(note_id, i) =>
							Object.keys(notes).includes(note_id) && (
								<Note
									key={note_id}
									note={notes[note_id]}
									noteWidth={gridNoteWidth}
									noteHeight={gridNoteHeight}
									visible={note_id !== noteToShow || !showNote}
									clickable
									accentHue={notes[note_id].accentHue}
									translateX={(i % columns) * (gridNoteWidth + 10)}
									translateY={Math.floor(i / columns) * (gridNoteHeight + 10)}
									onClick={() => {
										setNoteToShow(note_id);
										setShowNote(true);
									}}
								/>
							)
					)}
					<div
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}>
						{signedIn === false ? (
							<>
								<h1 style={{ margin: 5, textAlign: "center", fontSize: "22px" }}>
									Welcome to Flynn's Notes!
								</h1>
								<h1 style={{ margin: 5, marginBottom: 30, textAlign: "center", fontSize: "22px" }}>
									Please sign in:
								</h1>
								<GoogleButton type="light" onClick={signIn} />
							</>
						) : signedIn === null || !notesRetrieved ? (
							<h1 style={{ margin: 5, textAlign: "center", color: "#fff6", fontSize: "22px" }}>
								Loading...
							</h1>
						) : (
							sortedNoteIDs.length === 0 && (
								<h1 style={{ textAlign: "center", color: "#fff6", fontSize: "22px" }}>
									No notes.
									<br />
									Start a new one!
								</h1>
							)
						)}
					</div>
				</div>
				<NoteModal
					notes={notes}
					sortedNoteIDs={sortedNoteIDs}
					windowWidth={windowWidth}
					windowHeight={windowHeight}
					gridNoteWidth={gridNoteWidth}
					gridNoteHeight={gridNoteHeight}
					columns={columns}
					showNote={showNote}
					setShowNote={setShowNote}
					noteToShow={noteToShow}
				/>
			</div>
		</>
	);
}

function Note({
	note,
	noteWidth = 250,
	noteHeight = 120,
	visible = true,
	clickable = false,
	accentHue = -1,
	translateX = 0,
	translateY = 0,
	onClick,
}) {
	return (
		<div
			className={`note ${clickable ? "noteClickable" : ""} ${visible ? "" : "noteHide"}`}
			style={{
				width: noteWidth - 22,
				height: noteHeight - 22,
				"--accent-hue": accentHue > -1 ? `${accentHue}deg` : "",
				"--accent-saturation": accentHue > -1 ? "20%" : "",
				transform: `translate(${translateX}px, ${translateY}px)`,
			}}
			onClick={onClick}>
			<div className="noteHeader" style={{ margin: note.title ? "" : 0 }}>
				<h1>{note.title}</h1>
			</div>
			<div className="noteContent">
				<p>{note.content}</p>
			</div>
		</div>
	);
}

function NoteModal({
	notes,
	sortedNoteIDs,
	windowWidth,
	windowHeight,
	gridNoteWidth,
	gridNoteHeight,
	columns,
	showNote,
	setShowNote,
	noteToShow,
}) {
	const scrollbarExists =
		document.getElementsByTagName("html")[0].scrollHeight > document.getElementsByTagName("html")[0].clientHeight;

	return (
		<div
			className={`noteModal ${showNote ? "noteModalShow" : "noteModalShowa"}`}
			onClick={() => setShowNote(false)}>
			{sortedNoteIDs.map((note_id, i) => {
				return (
					<EditNote
						key={note_id}
						notes={notes}
						note_id={note_id}
						showNote={showNote && noteToShow === note_id}
						setShowNote={setShowNote}
						windowWidth={windowWidth}
						windowHeight={windowHeight}
						initalWidth={gridNoteWidth}
						initialHeight={gridNoteHeight}
						initialX={
							(i % columns) * (gridNoteWidth + 10) +
							(windowWidth - columns * (gridNoteWidth + 10)) / 2 -
							(scrollbarExists ? 4 : 0)
						}
						initialY={Math.floor(i / columns) * (gridNoteHeight + 10) + 70}
						scrollbarExists={scrollbarExists}
					/>
				);
			})}
			<EditNote
				notes={notes}
				note_id={-2}
				showNote={showNote && noteToShow === -2}
				windowWidth={windowWidth}
				windowHeight={windowHeight}
				initalWidth={windowWidth > 352 ? 301 : windowWidth - 50}
				initialHeight={37}
				initialX={windowWidth > 352 ? windowWidth / 2 - 158 : 20}
				initialY={1}
				scrollbarExists={scrollbarExists}
			/>
		</div>
	);
}

function EditNote({
	notes,
	note_id,
	showNote,
	setShowNote,
	windowWidth,
	windowHeight,
	initalWidth,
	initialHeight,
	initialX,
	initialY,
	scrollbarExists,
}) {
	const enlargedNoteWidth = Math.min(550, windowWidth);
	const enlargedNoteHeight = windowHeight * 0.8;
	const prevShowNote = useRef(showNote);
	const titleRef = useRef();
	const contentRef = useRef();
	const [isVisible, setIsVisible] = useState(false);
	const [actionsPage, setActionsPage] = useState("home");
	const [hideTransitions, setHideTransitions] = useState(false);
	const [, scrollY] = useWindowScroll();
	const [currentAccentHue, setCurrentAccentHue] = useState(notes[note_id]?.accentHue);

	useEffect(() => {
		if (!prevShowNote.current && showNote) {
			setIsVisible(true);
			if (note_id > -1) {
				titleRef.current.innerText = notes[note_id].title;
				contentRef.current.innerText = notes[note_id].content;
				setCurrentAccentHue(notes[note_id].accentHue);
			}

			if (contentRef.current) {
				setTimeout(() => {
					contentRef.current.focus();
					// Stole from Stack Overflow (but modified)
					if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
						var range = document.createRange();
						range.selectNodeContents(contentRef.current);
						range.collapse(false);
						var sel = window.getSelection();
						sel.removeAllRanges();
						sel.addRange(range);
					} else if (typeof document.body.createTextRange != "undefined") {
						var textRange = document.body.createTextRange();
						textRange.moveToElementText(contentRef.current);
						textRange.collapse(false);
						textRange.select();
					}
				}, 100);
			}

			setTimeout(() => setHideTransitions(true), 200);
		} else if (prevShowNote.current && !showNote) {
			setHideTransitions(false);
			setActionsPage("home");
			if (titleRef.current && contentRef.current) {
				const newTitle = titleRef.current.innerText;
				const newContent = contentRef.current.innerText;
				let newNote = notes[note_id];
				if (newTitle || newContent) {
					let my_id = note_id;
					if (note_id === -2) {
						do {
							my_id = Math.floor(100000 + Math.random() * 900000);
						} while (Object.keys(notes).includes(my_id));
						newNote = {
							title: "",
							content: "",
							accentHue: -1,
							created: Date.now(),
							edited: Date.now(),
						};
					}
					if (my_id > -1 && (newTitle !== notes[my_id]?.title || newContent !== notes[my_id]?.content))
						newNote.edited = Date.now();
					newNote.title = newTitle;
					newNote.content = newContent;
					newNote.accentHue = currentAccentHue ?? -1;

					const noteRef = ref(db, `users/${auth.currentUser.uid}/${my_id}`);
					set(noteRef, newNote);
				} else {
					// Delete note if title and content are empty
					if (note_id > -1) {
						const noteRef = ref(db, `users/${auth.currentUser.uid}/${note_id}`);
						remove(noteRef);
					}
				}
			}

			setTimeout(() => {
				setIsVisible(false);
				if (titleRef.current) titleRef.current.innerText = "";
				if (contentRef.current) contentRef.current.innerText = "";
				setCurrentAccentHue(-1);
			}, 200);
		}

		prevShowNote.current = showNote;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showNote]);

	return (
		<div
			className="editNote"
			key={note_id}
			style={{
				width: (showNote ? enlargedNoteWidth : initalWidth + 20) - 22,
				height: (showNote ? enlargedNoteHeight : initialHeight + 20) - 22,
				"--accent-hue": currentAccentHue > -1 ? `${currentAccentHue}deg` : "",
				"--accent-saturation": currentAccentHue > -1 ? "20%" : "",
				transform: `translateX(${
					showNote ? windowWidth / 2 - enlargedNoteWidth / 2 + (scrollbarExists ? 0 : 4) : initialX
				}px) translateY(${
					showNote ? (windowHeight - 25) / 2 - enlargedNoteHeight / 2 + 7.5 + scrollY : initialY
				}px)`,
				visibility: isVisible ? "visible" : "hidden",
				// visibility: isVisible ? "visible" : "visible",
				transition: hideTransitions && showNote ? "none" : "",
			}}
			onClick={(e) => {
				e.stopPropagation();
				setActionsPage("home");
			}}>
			<div className="editNoteContentWrapper">
				<div className="noteHeader">
					<h1
						ref={titleRef}
						contentEditable
						suppressContentEditableWarning
						placeholder="Title"
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault();
								contentRef.current.focus();
							} else if (
								e.key !== "Backspace" &&
								!e.ctrlKey &&
								!e.altKey &&
								!e.metaKey &&
								e.target.innerText.length > 512
							) {
								e.preventDefault();
							}
						}}
						onPaste={(e) => {
							e.preventDefault();
							const pasted = e.clipboardData.getData("text/plain");
							if (e.target.innerText.length + pasted.length <= 512) {
								document.execCommand("insertText", false, pasted);
							}
						}}>
						{notes[note_id]?.title ?? ""}
					</h1>
				</div>
				<div className="noteContent">
					<p
						ref={contentRef}
						contentEditable
						suppressContentEditableWarning
						placeholder="Take a note..."
						onPaste={(e) => {
							e.preventDefault();
							const pasted = e.clipboardData.getData("text/plain");
							document.execCommand("insertText", false, pasted);
						}}>
						{notes[note_id]?.content ?? ""}
					</p>
				</div>
				{note_id > -1 && (
					<div className="noteEdited">
						<p title={`Created ${new Date(notes[note_id]?.created).toLocaleString()}`}>
							Edited {new Date(notes[note_id]?.edited).toLocaleString()}
						</p>
					</div>
				)}
			</div>
			<div
				className="editNoteActions"
				style={{ backgroundColor: actionsPage === "changeColor" ? "hsl(220deg 5% 15%)" : "" }}
				onClick={(e) => e.stopPropagation()}>
				<div className={`actionsPage ${actionsPage === "home" ? "" : "actionsPageHide"}`}>
					<div className="changeColorAction" onClick={() => setActionsPage("changeColor")} />
					{note_id > -1 && (
						<MdDeleteOutline
							className="deleteAction"
							size="20px"
							onClick={() => setActionsPage("delete")}
						/>
					)}
				</div>
				<div className={`actionsPage ${actionsPage === "changeColor" ? "" : "actionsPageHide"}`}>
					<h1>Change color:</h1>
					{[-1, 0, 30, 60, 110, 180, 210, 250, 300].map((hue) => (
						<div
							key={hue}
							className="changeColorAction"
							style={{
								"--accent-hue": `${hue > -1 ? hue : 220}deg`,
								"--accent-saturation": `${hue > -1 ? 20 : 5}%`,
							}}
							onClick={() => setCurrentAccentHue(hue)}
						/>
					))}
				</div>
				<div
					className={`actionsPage ${actionsPage === "delete" ? "" : "actionsPageHide"}`}
					style={{ justifyContent: "flex-start" }}>
					<h1 style={{ width: "fit-content", marginRight: 5 }}>Delete note?</h1>
					<MdDeleteOutline
						className="deleteAction deleteActionConfirm"
						size="20px"
						onClick={() => {
							setShowNote(false);
							const noteRef = ref(db, `users/${auth.currentUser.uid}/${note_id}`);
							remove(noteRef);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default App;
